.button {
    background-color: transparent;
    border: 1px solid var(--color-dark);
    color: var(--color-dark);
    display: block;
    line-height: var(--font-lineheight);
    min-width: 10rem;
    padding: .5rem 1rem;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: color var(--animation-time), fill var(--animation-time);
    width: 100%;
}

.button svg {
    transition: fill var(--animation-time), color var(--animation-time);
}

@media (min-width: 400px) {
    .button {
        display: inline-block;
        /*width: initial;*/
    }
}

.button:hover:not(.light) {
    color: var(--color-light) !important;
}

.button:hover svg {
    fill: var(--color-light);
    color: var(--color-light);
}

.button::after {
    background: var(--color-dark);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: width var(--animation-time);
    width: 0;
}

.button:hover::after {
    width: 100%;
}

.button.icon {
    padding: .5rem 3rem .5rem 1rem;
}

.button.icon:hover svg {
    color: var(--color-light) !important;
}

.button.light {
    border: 1px solid var(--color-light);
    color: var(--color-light);
}

.button.light:hover {
    color: var(--color-dark);
}

.button.light::after {
    background: var(--color-light);
}

.button span,
.button svg {
    position: relative;
    z-index: 1;
}

.button svg {
    position: absolute;
    right: 1rem;
    top: calc(50% - .3rem);
}

.center {
    text-align: center;
}

.hidden {
    display: none;
}

.list-unstyled {
    list-style: none;
    margin: 0;
    padding: 0;
}

.list-unstyled li {
    margin: 0;
}

.noscroll {
    overflow: hidden;
}

.page-header {
    background: var(--color-blue);
    color: var(--color-light);
    margin: 0 auto;
    margin-top: var(--nav-height)
}

.page-header .header-container {
    display: flex;
    align-items: center;
    padding: 0 var(--block-space-horizontal);
}