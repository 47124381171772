@font-face {
    font-display: swap;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url(../fonts/montserrat-regular.woff2) format('woff2');
}

@font-face {
    font-display: swap;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    src: local('Montserrat Semibold'), local('Montserrat-Semibold'), url(../fonts/montserrat-semibold.woff2) format('woff2');
}

@font-face {
    font-display: swap;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url(../fonts/montserrat-bold.woff2) format('woff2');
}

