@import './variables.css';
@import './elements.css';
@import './styles.css';
@import './fonts.css';
@import './klaro/klaro.min.css';
@import './klaro/klaro-custom.css';


body {
    background-color: white; /* TODO change to white? */
}

.spaceTopAndBottom {
    padding-top: var(--default-spacing);
    padding-bottom: var(--default-spacing);
}

.spaceTopAndBottomLarge {
    padding-top: calc(var(--default-spacing) * 2);
    padding-bottom: calc(var(--default-spacing) * 2);
}

/* TODO would be useful to oursource to make sure this class is not overwritten by the blocks css because the app.css
    is loaded before the block css. (Or generally make sure this property is correctly used in blocks) One reason is the slider. The slider css was overriding the app css spacer class. */
.spaceBottom {
    padding-top: 0;
    padding-bottom: var(--default-spacing);
}
.spaceTop {
    padding-top: var(--default-spacing);
    padding-bottom: 0;
}

.space-both-side {
    padding: 0 var(--default-spacing);
}
.space-bottom-outer {
    margin-bottom: var(--default-spacing);
}

main .space-bottom-outer:last-child {
    margin-bottom: 0;
}

.spaceBottomLarge {
    padding-top: 0;
    padding-bottom: calc(var(--default-spacing) * 2);
}

main .spaceBottom:last-child {
    padding-bottom: 0;
}

main section:first-child {
    padding-top: var(--default-spacing);
}