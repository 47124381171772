:root {
    /* Animations */
    --animation-function: ease-in;
    --animation-time: 500ms;

    /* Colors */
    --color-blue: #4c43d1;
    --color-grey: #7b7b7b;
    --color-grey-light: #f0f0f0;
    --color-yellow: #f6ca45;
    --color-dark: black;
    --color-light: white;
    --color-font: var(--color-dark);
    --color-disabled: var(--color-grey);

    /* Fonts */
    --font-lineheight: 1.4;
    --font-family: Montserrat, sans-serif;

    --font-size: 1rem;
    --font-size-1: 1.75rem;
    --font-size-2: 1.3125rem;
    --font-size-3: 1.125rem;
    --font-size-4: 1rem;

    /* Heights */
    --nav-height: 4.75rem;

    /* Widths */
    --width-main: 90rem;
    --width-text: 48.25rem;
    --width-full: 100%;

    /* Space */
    --block-space-horizontal: 1.25rem;
    --block-space-vertical: 6rem;
    --block-space-vertical-small: 2rem;
    --default-spacing: 2rem;
}

@media (min-width: 480px) {
    :root {
        /* Space*/
        --block-space-horizontal: 2rem;
    }
}

@media (min-width: 1080px) {
    :root {
        /* Space */
        --block-space-horizontal: 3.125rem;

        /* Fonts */
        --font-size-1: 3.375rem;
        --font-size-2: 2.25rem;
        --font-size-3: 1.5rem;
    }
}
