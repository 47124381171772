*,
*::before,
*::after {
    box-sizing: inherit;
}

a {
    color: var(--color-font);
}

a:active,
a:hover {}

body {
    background: var(--color-light);
    color: var(--color-font);
    font-family: var(--font-family);
    font-size: var(--font-size);
    line-height: var(--font-lineheight);
    margin: 0;
}

button,
input[type=button],
input[type=search],
input[type=submit],
input[type=text],
select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    background-position: center right 1rem;
    background-repeat: no-repeat;
    border: 1px solid var(--color-dark);
    color: var(--color-dark);
    font-family: var(--font-family);
    font-size: var(--font-size);
    line-height: var(--font-lineheight);
    min-width: 5rem;
    padding: .5rem 1rem;
    text-align: left;
}

select {
    background-image: url(../img/arrdown.svg);
    padding: .5rem 3rem .5rem 1rem;
}

button:active, button:focus, button:hover,
input:active, input:focus, input:hover,
select:active, select:focus, select:hover {}

button,
input[type=button] {
    cursor: pointer;
}

button:disabled,
input[type=button]:disabled {
    border-color: var(--color-disabled);
    color: var(--color-disabled);
    cursor: default;
}

button:hover,
input[type=button]:hover {
    text-decoration: underline;
}

button:disabled:hover,
input[type=button]:disabled:hover {
    outline: none;
    text-decoration: none;
}

figcaption {
    font-weight: bold;
    padding: 1rem 0;
}

figure {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    word-break: break-word;
}

h1 {
    font-size: var(--font-size-1);
    line-height: 2rem;
}

h2 {
    font-size: var(--font-size-2);
    line-height: 1.625rem;
}

h3 {
    font-size: var(--font-size-3);
    line-height: 1.5rem;
}

h4 {
    font-size: var(--font-size-4);
    line-height: 1.5rem;
}

@media (min-width: 1080px) {
    h1 {
        font-size: var(--font-size-1);
        line-height: 3.625rem;
    }

    h2 {
        font-size: var(--font-size-2);
        line-height: 2.625rem;
    }

    h3 {
        font-size: var(--font-size-3);
        line-height: 1.75rem;
    }

    h4 {
        font-size: var(--font-size-4);
        line-height: 1.5rem;
    }
}

html {
    box-sizing: border-box;
    font-size: 100%;
    scroll-behavior: smooth;
}

img {
    display: block;
    max-width: 100%;
}

li {
    font-size: var(--font-size-p);
    margin: 0 0 1rem;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

input::placeholder,
select::placeholder {
    color: var(--color-disabled);
    opacity: 1;
}

p {
    margin: 0 0 1rem;
}

picture,
picture source {
    display: block;
}

ul {
    margin: 0 0 2rem;
}

video,
video source {
    display: block;
    max-width: 100%;
}
